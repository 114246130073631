<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="用户">
            <template #default="{ row }">
              <el-image v-if="row.avatar" :src="row.avatar" class="wechat-user-avatar"></el-image>
              {{ row.nickname }}
            </template>
          </el-table-column>
          <el-table-column label="手机号" align="center">
            <template #default="{ row }">
              <el-link
                v-if="row.mobile"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.mobile}`"
              >{{ row.mobile }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="企业" prop="company.company_name" align="center"></el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Phone } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/wechatUser/list', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

onMounted(() => {
  getList();
});
</script>

<style lang="less">
.wechat-user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
}
</style>
